@import "../../../../../../lib/uio1/profile/css/util.less";

#hidnav a[href="#bottomnav"] {
  display: none;
}

.not-for-ansatte {
  button:not(.comment-delete-button):not(#vrtx-comments-delete-all):not(.red):not(.program-create-print):not(.program-create-send-dialog):not(.vrtx-program-read-more):not(.vrtx-program-how-search):not([class^="admin-frontpage"]):not([class^="admin-box-row"]):not([class^="vrtx-frontpage-box"]):not([class^="change-positions-in-row"]):not([class^="vrtx-edit-"]):not(.undo):not(.x-cancel),
  button:not(.comment-delete-button):not(#vrtx-commments-delete-all):not(.red)
    input[type="button"]:not(.red),
  input[type="submit"]:not(.submit-comment-button):not(.red),
  input[type="cancel"]:not(.red),
  .header-search-expand,
  button:not([class^="admin-frontpage"]):not([class^="admin-box-row"]):not([class^="vrtx-frontpage-box"]):not([class^="change-positions-in-row"]):not([class^="vrtx-edit-"]):not(.undo):not(.x-cancel) {
    background-color: @color-primary !important;
    .transition(all, 0.5s, ease-in);
    &:focus,
    &:hover {
      background-color: @color-primary--dark !important;
    }
  }
  .header-search {
    top: 18px;
    right: 112px;
    z-index: 10;
    input[type="text"] {
      background: @color-neutral--light;
      .placeholderColor(@color-dark, @color-dark) !important;
      color: @color-neutral--dark;
    }
  }
  #head-wrapper {
    background: @color-light url("../images/logo.jpg") no-repeat scroll center
      top;
    .head-menu .vrtx-login-manage-component .vrtx-dropdown-link {
      background: transparent
        url("../uio1/images/head-dropdown-toggle-black.png")
        no-repeat scroll 90% 53%;
    }
    #head {
      .uio-app-name {
        display: none;
      }
      height: 140px;
      .head-menu {
        top: 15px;
        right: 220px;
        .language {
          display: none;
        }
        a {
          color: @color-dark;
          background-color: @color-light;
          padding: 6px 30px 6px 15px;
          border-radius: 3px;
        }
        .vrtx-dropdown-wrapper ul li a {
          color: @color-link !important;
        }
      }
      #header {
        img {
          position: absolute;
          top: 25px;
        }
        .logo-wrapper {
          background: transparent;
          width: 140px;
          height: 140px;
          display: inline-block;
        }
      }
    }
  }
}
#globalnav {
  background: @color-primary none repeat scroll 0 0;
  ul li {
    a {
      color: @color-neutral--light;
      &:hover,
      &:focus {
        color: @color-neutral--dark;
        background: @color-neutral--light !important;
      }
    }
    &.vrtx-active-item a {
      color: @color-dark;
      &:hover,
      &:focus {
        background: @color-neutral--light none repeat scroll 0 0;
        color: @color-dark;
        margin-top: 0;
      }
    }
    &.vrtx-active-item a {
      background: @color-light none repeat scroll 0 0;
      color: @color-neutral--dark;
    }
  }
}
#main {
  min-height: 300px;
}
#left-main ul.vrtx-breadcrumb-menu li {
  &.vrtx-child {
    border-bottom: 1px @color-neutral--air solid;
    a {
      color: @color-link;
      background-color: @color-neutral--light;
      border-top: none !important;
      background: rgba(0, 0, 0, 0)
        url("/vrtx/decorating/resources/dist/images/li-arrow.png") no-repeat
        scroll 10px 23px;
    }
  }
  a {
    &.vrtx-marked {
      background-color: @color-secondary !important;
      color: @color-dark;
      border-top: none !important;
    }
    &:hover,
    &:focus {
      background-color: @color-neutral--light;
    }
  }
  span::before,
  a::before {
    content: " " !important;
  }
}

table th {
  font-weight: bold;
}

#vrtx-additional-content .vrtx-frontpage-box {
  border-bottom: 4px solid #eaeaea;
}
#vrtx-main-content-2,
.vrtx-frontpage-full-width #main .row-all-colored {
  background-color: #e3ddd5;
}

#bottomnav {
  display: none !important;
}

#uio-app-menu-wrapper,
#footer-wrapper {
  background: @footer-color;
}
#main .vrtx-back a::before {
  background: url("../images/icon-chevron-back-brown.svg")
    no-repeat 0 0.2ex;
}
a.vrtx-more::before,
div.vrtx-more a::before,
a.all-messages::before,
a.all-comments::before,
.footer > .vrtx-search-scope a::before,
a.more-url::before {
  background: url("../images/arrow-forward-brown.svg")
    no-repeat 0 3px !important;
}
.ui-accordion.ui-widget {
  .ui-accordion-header .ui-icon {
    background: transparent url("../images/icon-chevron-right-brown.svg")
      no-repeat center left;
  }
  .ui-accordion-header.ui-accordion-header-active .ui-icon {
    background-image: url("../images/icon-chevron-down-brown.svg");
  }
}
//footer
.not-for-ansatte {
  &:not(.header-context) {
    .responsible-login .content {
      margin-left: 90px;
    }
  }
  #footer-wrapper {
    background-color: @color-primary;
    *,
    a {
      color: @color-light;
    }
    .vrtx-dropdown-component-toggled .vrtx-login-manage-link {
      background: rgba(0, 0, 0, 0)
        url("../uio1/images/dropdown-black.png")
        no-repeat scroll right center / 22px auto;
      &:focus,
      &:hover {
        background-image: url("../uio1/images/dropdown-black-hover.png");
      }
    }
  }
}

@media only screen and (max-width: 16cm) and (orientation: portrait),
  only screen and (max-width: 19cm) and (orientation: landscape) {
  .not-for-ansatte {
    #nav-offcanvas #lang-link {
      display: none;
    }
    #head-wrapper {
      background: @color-light url("../images/logo-mob4.jpg") no-repeat left top;
      /*background: none @color-light;*/
      /*height: 178px;*/
      #head {
        /*background: transparent url("../images/logo.png") no-repeat center top;*/
        #header {
          img {
            padding: 0 10px 10px;
          }
        }
      }
    }
    #menu-wrapper .menu li.active,
    #menu-wrapper .menu {
      background: @color-primary none repeat scroll 0 0 !important;
    }
  }
  .menu-search {
    input[type="text"] {
      border-right-width: 7em !important;
      padding-right: 0px;
    }
    button {
      background-color: @color-primary;
      .transition(all, 0.5s, ease-in);
      &:focus,
      &:hover {
        background-color: @color-primary--dark;
      }
    }
  }
  #vrtx-additional-content .vrtx-frontpage-box {
    border-bottom: none;
  }
}

/* Mixin */

.transition (@prop: all, @time: 1s, @ease: linear) {
  -webkit-transition: @prop @time @ease;
  -moz-transition: @prop @time @ease;
  -o-transition: @prop @time @ease;
  -ms-transition: @prop @time @ease;
  transition: @prop @time @ease;
}

/*------------------------------------*\
# COLOR
\*------------------------------------*/

// Primary, secondary etc...

@color-primary : #8B7355;
@color-primary--dark : #E3DDD5;
@color-secondary : #ffffff;
@color-secondary--dark : darken(spin(@color-secondary, -5), 10%);
@color-link : #2771bb;

// Neutral colors
@color-neutral--dark : #2b2b2b;
@color-neutral : #949494;
@color-neutral--light : #EAEDEE;
@color-neutral--air : #e5e5e5;
@color-lines : #e4e4e4;

// Suplementary colors
@color-light : #ffffff;
@color-dark : #000000;
@footer-color : @color-neutral--light;

// Spacing
@unit : 5px;
@spacing--xlarge : 100px;
@spacing--large : 40px;
@spacing : 20px;
@spacing--small : 10px;
